import { useEffect, useRef } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { GOOGLE_MAPS_API_STATE } from '~/src/stores/google-maps-api'

const useGoogleMapsAutocompleteService = (): google.maps.places.AutocompleteService | null => {
  const { isLoaded } = useEoValue(GOOGLE_MAPS_API_STATE)
  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null)

  useEffect(() => {
    if (!isLoaded || autocompleteService.current) {
      return
    }

    autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
  }, [ isLoaded ])

  return autocompleteService.current
}

export default useGoogleMapsAutocompleteService

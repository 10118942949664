import getLocatorDeliveryMethodsTemplateService
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/GetLocatorDeliveryMethodsTemplateService'
import StylesLocatorDeliveryMethodsServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorDeliveryMethodsServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorDeliveryMethodsTemplate = (): StylesLocatorDeliveryMethodsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorDeliveryMethodsTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorDeliveryMethodsTemplate

import { SxProps } from '@mui/material'
import StylesLocatorFirmCardImageServiceInterface
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/StylesLocatorFirmCardImageServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmCardImageServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorFirmCardImageServiceInterface {
  public getImageSxProps(): SxProps {
    return {
      display: 'none',
      gridArea: 'firm-image',
      backgroundColor: 'wells.main',
      color: 'wells.contrastText',
      width: '100%',
      aspectRatio: '4 / 3',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  }
}

import StylesLocatorLeftContainerDefaultServiceInterface
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/StylesLocatorLeftContainerDefaultServiceInterface'
import StylesLocatorLeftContainerDefaultServiceStyleOne
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/StylesLocatorLeftContainerDefaultServiceStyleOne'
import StylesLocatorLeftContainerDefaultServiceStyleTwo
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/StylesLocatorLeftContainerDefaultServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import { LayoutState } from '~/src/stores/layout'

const getLocatorLeftContainerDefaultTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme, layout: LayoutState): StylesLocatorLeftContainerDefaultServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorLeftContainerDefaultServiceStyleTwo(muiTheme, undefined, layout)
    default:
      return new StylesLocatorLeftContainerDefaultServiceStyleOne(muiTheme, undefined, layout)
  }
}

export default getLocatorLeftContainerDefaultTemplateService

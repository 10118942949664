import { Position } from '@capacitor/geolocation'
import { DeliveryMethodEnum, Firm, Geocode } from '@eo-storefronts/eo-core'
import { Constants } from '~/src/helpers/constants'

export interface FilterMethods {
  input(firms: Firm[], value: string): Firm[],
  delivery_methods(firms: Firm[], value: DeliveryMethodEnum): Firm[],
  geo(firms: Firm[], position: GeolocationPosition | Position): Firm[],
}

interface FirmWithDistance {
  distance: number,
  firm: Firm,
}

const _filterOnDeliveryMethod = (firms: Firm[], value: DeliveryMethodEnum): Firm[] => {
  if (!value) {
    return firms
  }

  return firms.filter((firm: Firm) => Object.keys(firm.settings.delivery_methods).includes(value))
}

const _filterOnFullAddress = (firms: Firm[], value: string): Firm[] => {
  return firms.filter((firm: Firm) => firm.address.address.toLowerCase().includes(value.toLowerCase()))
}

const _filterByGeolocation = (firms: Firm[], position: GeolocationPosition | Position): Firm[] => {
  const firmsCopy: Array<FirmWithDistance> = [ ...firms ].map((firm: Firm) => (
    {
      firm,
      distance: 0
    }
  ))

  // calculate the distance
  firmsCopy.forEach((firm: FirmWithDistance) => {
    const firmGeoCode: Geocode = firm.firm.address.geocode
    const myGeoCoordinates: Geocode = {
      lat: position.coords.latitude.toString(),
      long: position.coords.longitude.toString()
    }

    firm.distance = _getDistanceBetweenPoints(myGeoCoordinates, firmGeoCode)
  })

  firmsCopy.sort((a: FirmWithDistance, b: FirmWithDistance) => a.distance - b.distance)

  return firmsCopy.map((f: FirmWithDistance) => f.firm)
}

/**
 * GENERATED WITH CHAT-GPT
 *
 * In the getDistanceBetweenPoints method that I previously wrote,
 * the variables a and c are used to calculate the distance between two geographical points using the Haversine formula.
 *
 * The variable a is calculated as follows:
 *
 * a = sin²(Δlat/2) + cos(lat1) * cos(lat2) * sin²(Δlon/2)
 * where Δlat is the difference in latitude between the two points,
 * Δlon is the difference in longitude between the two points,
 * lat1 is the latitude of the first point,
 * lat2 is the latitude of the second point, cos is the cosine function, and sin is the sine function.
 *
 * The variable c is then calculated as follows:
 *
 * c = 2 * atan2(√a, √(1-a))
 * where atan2 is a trigonometric function that calculates the arctangent of two variables,
 * a is the variable defined previously, and √ is the square root function.
 *
 * The variable c represents the angular distance between the two points, in radians.
 * This angular distance is then multiplied by the radius of the Earth to obtain the distance between the two points in kilometers.
 * @param {Geocode} point1
 * @param {Geocode} point2
 * @returns {number}
 */
const _getDistanceBetweenPoints = (point1: Geocode, point2: Geocode) => {
  const earthRadius = Constants.EARTH_RADIUS
  const latDiff = _degreesToRadians(Number(point2.lat) - Number(point1.lat))
  const lonDiff = _degreesToRadians(Number(point2.long) - Number(point1.long))

  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(_degreesToRadians(Number(point1.lat))) *
    Math.cos(_degreesToRadians(Number(point2.lat))) *
    Math.sin(lonDiff / 2) *
    Math.sin(lonDiff / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return earthRadius * c
}

const _degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180
}

const filtersMethods: FilterMethods = {
  input: _filterOnFullAddress,
  delivery_methods: _filterOnDeliveryMethod,
  geo: _filterByGeolocation
}

export default filtersMethods

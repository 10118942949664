import getLocatorFirmCardImageTemplateService
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/GetLocatorFirmCardImageTemplateService'
import StylesLocatorFirmCardImageServiceInterface
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/StylesLocatorFirmCardImageServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorFirmCardImageTemplate = (): StylesLocatorFirmCardImageServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorFirmCardImageTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorFirmCardImageTemplate

import { SxProps } from '@mui/material'
import StylesLocatorFirmSubTitleServiceInterface
  from '~/src/components/locator/locator-firm-sub-title/styles/StylesLocatorFirmSubTitleServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmSubTitleServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorFirmSubTitleServiceInterface {
  public getTitleSx(): SxProps {
    return {}
  }
}

import { SxProps } from '@mui/material'
import StylesLocatorRightContainerServiceInterface
  from '~/src/components/locator/locator-right-container/styles/StylesLocatorRightContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorRightContainerServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorRightContainerServiceInterface {
  public getRightContainerSx(headerHeight: number): SxProps {
    return {
      gridArea: 'right',
      '> img': {
        position: 'fixed',
        objectFit: 'cover',
        objectPosition: 'bottom',
        minWidth: '50%',
        height: `calc(var(--page-height) - ${headerHeight}px)`
      },
      [this.muiTheme.breakpoints.only('xs')]: {
        maxHeight: '200px',
        overflow: 'hidden',
        img: {
          position: 'relative',
          maxWidth: '100%',
          objectFit: 'cover'
        }
      }
    }
  }
}

import getLocatorGeoPositionFilterTemplateService
  from '~/src/components/locator/locator-geo-position-filter/styles/GetLocatorGeoPositionFilterTemplateService'
import StylesLocatorGeoPositionFilterServiceInterface
  from '~/src/components/locator/locator-geo-position-filter/styles/StylesLocatorGeoPositionFilterServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorGeoPositionFilterTemplate = (): StylesLocatorGeoPositionFilterServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorGeoPositionFilterTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorGeoPositionFilterTemplate

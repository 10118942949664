import StylesLocatorFirmCardServiceInterface
  from '~/src/components/locator/locator-firm-card/styles/StylesLocatorFirmCardServiceInterface'
import StylesLocatorFirmCardServiceStyleOne
  from '~/src/components/locator/locator-firm-card/styles/StylesLocatorFirmCardServiceStyleOne'
import StylesLocatorFirmCardServiceStyleTwo
  from '~/src/components/locator/locator-firm-card/styles/StylesLocatorFirmCardServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorFirmCardTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorFirmCardServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorFirmCardServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorFirmCardServiceStyleOne(muiTheme)
  }
}

export default getLocatorFirmCardTemplateService

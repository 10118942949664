import { styled } from '@mui/material'
import { TabUnstyled, TabUnstyledProps } from '@mui/base'
import useGetTabTemplate from '~/src/components/mui-wrappers/styled/delivery-methods/tab/styles/useGetTabTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR } from '~/src/stores/firms'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'

const DeliveryMethodTab = styled(({ value, ...otherProps }: TabUnstyledProps) => {
  const firmsWithPickupMethod = useEoValue(LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR(value as DeliveryMethodNames))

  if (!firmsWithPickupMethod.length) {
    return null
  }

  return <TabUnstyled value={value} {...otherProps} />
})(() => useGetTabTemplate().getComponentSx())

export default DeliveryMethodTab

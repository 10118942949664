import getLocatorFirmTitleTemplateService
  from '~/src/components/locator/locator-firm-title/styles/GetLocatorFirmTitleTemplateService'
import StylesLocatorFirmTitleServiceInterface
  from '~/src/components/locator/locator-firm-title/styles/StylesLocatorFirmTitleServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorFirmTitleTemplate = (): StylesLocatorFirmTitleServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorFirmTitleTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorFirmTitleTemplate

import getLocatorFirmCardActionsTemplateService
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/GetLocatorFirmCardActionsTemplateService'
import StylesLocatorFirmCardActionsServiceInterface
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/StylesLocatorFirmCardActionsServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorFirmCardActionsTemplate = (): StylesLocatorFirmCardActionsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorFirmCardActionsTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorFirmCardActionsTemplate

import Box from '@mui/material/Box'

interface Props {
  part: { text: string, highlight: boolean },
}

const AutocompleteRenderOptionPart = ({ part }: Props) => {
  return (
    <Box
      component='span'
      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
    >
      {part.text}
    </Box>
  )
}

export default AutocompleteRenderOptionPart

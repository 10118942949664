import { buttonUnstyledClasses, tabUnstyledClasses } from '@mui/base'
import StylesTabServiceInterface from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabServiceStyleTwo extends TemplateServiceStyleBase implements StylesTabServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      cursor: 'pointer',
      minWidth: '145px',
      margin: 0,
      fontSize: '0.875rem',
      fontWeight: 'bold',
      backgroundColor: this.muiTheme.palette.primary.main,
      color: this.muiTheme.palette.primary.contrastText,
      padding: '12px',
      border: 'none',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      [`&.${tabUnstyledClasses.selected}`]: {
        backgroundColor: this.muiTheme.palette.secondary.main,
        color: this.muiTheme.palette.secondary.contrastText
      },
      [`&.${buttonUnstyledClasses.disabled}`]: {
        opacity: '0.5',
        cursor: 'not-allowed'
      }
    }
  }
}

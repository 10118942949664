import { CSSProperties } from 'react'
import StylesLocatorMapContainerServiceInterface
  from '~/src/components/locator/locator-map-container/styles/StylesLocatorMapContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorMapContainerServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorMapContainerServiceInterface {
  public getMapContainerSx(): CSSProperties {
    return {
      position: 'fixed',
      height: 'calc(var(--page-height) - 82px)',
      width: '50%',
      [this.muiTheme.breakpoints.down('md')]: {
        position: 'relative',
        width: '100%'
      }
    }
  }
}

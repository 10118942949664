import { useEffect } from 'react'
import LocatorContainer from '~/src/components/locator/locator-container'
import useGetLocatorTemplate from '~/src/pages/locator/styles/useGetLocatorTemplate'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import { useSetEoState } from '~/src/hooks/useEoState'
import { LOCATOR_STATE, locatorStateInitialState } from '~/src/stores/locator'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { Constants } from '~/src/helpers/constants'

const Locator = () => {
  const pageName: keyof typeof RoutesEnum = 'LOCATOR'
  const setLocatorState = useSetEoState(LOCATOR_STATE)

  useGetLocatorTemplate()

  useEffect(() => {
    return () => setLocatorState(locatorStateInitialState())
  }, [])

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: .5,
        delay: Constants.MOTION_ANIMATION_DELAY
      }}
    >
      <DocumentTitleInjector pageName={pageName}/>
      <LocatorContainer/>
    </SlideFromRight>
  )
}

export default Locator

import StylesLocatorMapContainerServiceInterface
  from '~/src/components/locator/locator-map-container/styles/StylesLocatorMapContainerServiceInterface'
import StylesLocatorMapContainerServiceStyleOne
  from '~/src/components/locator/locator-map-container/styles/StylesLocatorMapContainerServiceStyleOne'
import StylesLocatorMapContainerServiceStyleTwo
  from '~/src/components/locator/locator-map-container/styles/StylesLocatorMapContainerServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorMapContainerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorMapContainerServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorMapContainerServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorMapContainerServiceStyleOne(muiTheme)
  }
}

export default getLocatorMapContainerTemplateService

import { SxProps } from '@mui/material'
import StylesLocatorRightContainerServiceInterface
  from '~/src/components/locator/locator-right-container/styles/StylesLocatorRightContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorRightContainerServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorRightContainerServiceInterface {
  public getRightContainerSx(headerHeight: number): SxProps {
    return {
      gridArea: 'right',
      position: 'relative',
      left: '-10%',
      '> img': {
        position: 'fixed',
        minWidth: '80%',
        objectFit: 'cover',
        height: `calc(var(--page-height) - ${headerHeight}px)`
      },
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }
}

import StylesLocatorRightContainerServiceInterface
  from '~/src/components/locator/locator-right-container/styles/StylesLocatorRightContainerServiceInterface'
import StylesLocatorRightContainerServiceStyleOne
  from '~/src/components/locator/locator-right-container/styles/StylesLocatorRightContainerServiceStyleOne'
import StylesLocatorRightContainerServiceStyleTwo
  from '~/src/components/locator/locator-right-container/styles/StylesLocatorRightContainerServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorRightContainerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorRightContainerServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorRightContainerServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorRightContainerServiceStyleOne(muiTheme)
  }
}

export default getLocatorRightContainerTemplateService

import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import { SyntheticEvent, useEffect } from 'react'
import LocatorDeliveryTab
  from '~/src/components/locator/locator-delivery-methods-container/locator-delivery-tab-content'
import LocatorPickupTab from '~/src/components/locator/locator-delivery-methods-container/locator-pickup-tab-content'
import DeliveryMethodTab from '~/src/components/mui-wrappers/styled/delivery-methods/tab'
import DeliveryMethodTabList from '~/src/components/mui-wrappers/styled/delivery-methods/tab-list'
import DeliveryMethodTabPanel from '~/src/components/mui-wrappers/styled/delivery-methods/tab-panel'
import DeliveryMethodsTabs from '~/src/components/mui-wrappers/styled/delivery-methods/tabs'
import { useEoState, useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { CHECKOUT_FORM_STATE, checkoutFormInitialState, CheckoutFormState } from '~/src/stores/checkout'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR } from '~/src/stores/firms'

const LocatorDeliveryMethodsContainer = () => {
  const { t } = useTranslations()
  const setLocatorState = useSetEoState<LocatorState>(LOCATOR_STATE)
  const [ checkoutForm, setCheckoutForm ] = useEoState<CheckoutFormState>(CHECKOUT_FORM_STATE)

  //TODO replace by locationsWithDeliveryMethodSelector when brand locations gonna be updated
  const firmsWithPickupMethod = useEoValue(LOCATIONS_WITH_DELIVERY_METHOD_SELECTOR(DeliveryMethodNames.PICKUP))

  const handleOnTabChange = (event: SyntheticEvent, value: string | number | boolean) => {
    const defaultCheckoutState: CheckoutFormState = checkoutFormInitialState()

    setLocatorState({ firmSelected: undefined, isLoading: false })
    setCheckoutForm({
      ...defaultCheckoutState,
      delivery_method: {
        ...defaultCheckoutState.delivery_method,
        method: value as DeliveryMethodNames
      }
    })
  }

  useEffect(() => {
    if (checkoutForm.delivery_method.method) {
      return
    }

    setCheckoutForm((state: CheckoutFormState) => ({
      ...state,
      delivery_method: {
        ...state.delivery_method,
        method: firmsWithPickupMethod.length ? DeliveryMethodNames.PICKUP : DeliveryMethodNames.DELIVERY
      }
    }))

    return () => {
      setLocatorState({ firmSelected: undefined, isLoading: false })
    }
  }, [])

  return (
    <DeliveryMethodsTabs
      value={checkoutForm.delivery_method.method as string}
      onChange={handleOnTabChange}
    >
      <DeliveryMethodTabList>
        <DeliveryMethodTab value={DeliveryMethodNames.PICKUP}>
          {t('delivery_methods.pickup')}
        </DeliveryMethodTab>
        <DeliveryMethodTab value={DeliveryMethodNames.DELIVERY}>
          {t('delivery_methods.delivery')}
        </DeliveryMethodTab>
      </DeliveryMethodTabList>
      <DeliveryMethodTabPanel value={DeliveryMethodNames.PICKUP}>
        <LocatorPickupTab />
      </DeliveryMethodTabPanel>
      <DeliveryMethodTabPanel value={DeliveryMethodNames.DELIVERY}>
        <LocatorDeliveryTab />
      </DeliveryMethodTabPanel>
    </DeliveryMethodsTabs>
  )
}

export default LocatorDeliveryMethodsContainer

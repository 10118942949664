import StylesTabListServiceInterface
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab-list/styles/StylesTabListServiceInterface'
import StylesTabListServiceStyleOne
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab-list/styles/StylesTabListServiceStyleOne'
import StylesTabListServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab-list/styles/StylesTabListServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getTabListTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesTabListServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesTabListServiceStyleTwo(muiTheme)
    default:
      return new StylesTabListServiceStyleOne(muiTheme)
  }
}

export default getTabListTemplateService

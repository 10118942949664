import { SxProps } from '@mui/material'
import StylesLocatorFirmCardServiceInterface
  from '~/src/components/locator/locator-firm-card/styles/StylesLocatorFirmCardServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmCardServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorFirmCardServiceInterface {
  public getCardContainerSx(): SxProps {
    return {
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      display: 'grid',
      borderRadius: '20px',
      gridTemplateAreas: '"firm-image" "firm-card-content"',
      gridTemplateRows: 'max-content auto',
      boxShadow: this.muiTheme.elevation,
      height: '100%',
      cursor: 'pointer'
    }
  }

  public getCardContentSx(): SxProps {
    return {
      p: 2,
      pt: 1,
      gridArea: 'firm-card-content',
      display: 'grid',
      gridTemplateAreas: '"firm-info" "firm-timetable" "firm-actions"',
      gridTemplateRows: 'auto max-content max-content'
    }
  }

  public getCardInfoSx(): SxProps {
    return {
      gridArea: 'firm-info',
      display: 'grid',
      gridTemplateRows: 'auto max-content',
      flex:' 1 1 100%',
      minWidth: '0'
    }
  }

  public getCardInfoTitleSx(): SxProps {
    return {
      wordBreak: 'break-word'
    }
  }

  public getCardInfoTypographySx(): SxProps {
    return {
      mt: 1,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden'
    }
  }

  public getCardTimetableSx(): SxProps {
    return {
      gridArea: 'firm-timetable',
      pb: 1
    }
  }

  public getCardActionsSx(): SxProps {
    return {
      gridArea: 'firm-actions',
      '> a': {
        width: '100%',
        '> button': {
          width: '100%'
        }
      }
    }
  }
}

import StylesLocatorLeftContainerServiceInterface
  from '~/src/components/locator/locator-left-container/styles/StylesLocatorLeftContainerServiceInterface'
import StylesLocatorLeftContainerServiceStyleOne
  from '~/src/components/locator/locator-left-container/styles/StylesLocatorLeftContainerServiceStyleOne'
import StylesLocatorLeftContainerServiceStyleTwo
  from '~/src/components/locator/locator-left-container/styles/StylesLocatorLeftContainerServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorLeftContainerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorLeftContainerServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorLeftContainerServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorLeftContainerServiceStyleOne(muiTheme)
  }
}

export default getLocatorLeftContainerTemplateService

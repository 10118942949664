import StylesLocatorFirmTitleServiceInterface
  from '~/src/components/locator/locator-firm-title/styles/StylesLocatorFirmTitleServiceInterface'
import StylesLocatorFirmTitleServiceStyleOne
  from '~/src/components/locator/locator-firm-title/styles/StylesLocatorFirmTitleServiceStyleOne'
import StylesLocatorFirmTitleServiceStyleTwo
  from '~/src/components/locator/locator-firm-title/styles/StylesLocatorFirmTitleServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorFirmTitleTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorFirmTitleServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorFirmTitleServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorFirmTitleServiceStyleOne(muiTheme)
  }
}

export default getLocatorFirmTitleTemplateService

import getLocatorFirmCardTemplateService
  from '~/src/components/locator/locator-firm-card/styles/GetLocatorFirmCardTemplateService'
import StylesLocatorFirmCardServiceInterface
  from '~/src/components/locator/locator-firm-card/styles/StylesLocatorFirmCardServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorFirmCardTemplate = (): StylesLocatorFirmCardServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorFirmCardTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorFirmCardTemplate

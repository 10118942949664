import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Box } from '@mui/material'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { APP_LOADER_STATE } from '~/src/stores/app-loader'
import LocatorState, { LOCATOR_NEXT_STEP_BUTTON_DISABLED_SELECTOR, LOCATOR_STATE } from '~/src/stores/locator'
import { CHECKOUT_FORM_STATE } from '~/src/stores/checkout'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import useFetchDeliveryAddressCost from '~/src/hooks/delivery-methods/useFetchDeliveryAddressCost'
import useLocationUrl from '~/src/hooks/locator/useLocationUrl'

const LocatorTabActions = () => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const setAppLoaderState = useSetEoState(APP_LOADER_STATE)
  const { firmSelected: firm, isLoading } = useEoValue<LocatorState>(LOCATOR_STATE)
  const nextStepButtonDisabled = useEoValue<boolean>(LOCATOR_NEXT_STEP_BUTTON_DISABLED_SELECTOR)
  const checkoutForm = useEoValue(CHECKOUT_FORM_STATE)
  const { fetchDeliveryCost } = useFetchDeliveryAddressCost()
  const { getHref } = useLocationUrl()

  const handleLinkClicked = async () => {
    if (!firm) {
      return
    }

    setAppLoaderState({
      type: 'standard',
      state: true
    })

    if (checkoutForm.delivery_method.method === DeliveryMethodNames.DELIVERY) {
      await fetchDeliveryCost(checkoutForm.delivery_method.address!)
    }

    push(getHref(firm))
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      <Button
        variant='contained'
        sx={{ boxShadow: 2 }}
        disabled={nextStepButtonDisabled}
        loading={isLoading}
        onClick={handleLinkClicked}
      >
        <span>{t('locator.next')}</span>
        <ArrowForwardIcon sx={{ ml: 1 }} />
      </Button>
    </Box>
  )
}

export default LocatorTabActions

import { Geolocation, Position } from '@capacitor/geolocation'
import { useEffect, useState } from 'react'

interface ReturnsType {
  myPosition: GeolocationPosition | Position | null,
}

const usePosition = (): ReturnsType => {
  const [ myPosition, setMyPosition ] = useState<GeolocationPosition | Position | null>(null)

  useEffect(() => {
    Geolocation
      .getCurrentPosition()
      .then((position: Position) => {
        setMyPosition(position)
      })
      .catch(() => {
        // Ignore
      })
  }, [ setMyPosition ])

  return { myPosition }
}

export default usePosition

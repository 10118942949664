import getFirmsLocatorTemplateService
  from '~/src/components/locator/locator-filters/styles/GetFirmsLocatorTemplateService'
import StylesFirmsLocatorServiceInterface
  from '~/src/components/locator/locator-filters/styles/StylesFirmsLocatorServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetFirmsLocatorTemplate = (): StylesFirmsLocatorServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getFirmsLocatorTemplateService(pageStyle, muiTheme)
}

export default useGetFirmsLocatorTemplate

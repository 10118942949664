import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import DeliveryAddress from '~/src/components/delivery-methods/DeliveryAddress'
import LocatorTabActions from '~/src/components/locator/locator-delivery-methods-container/LocatorTabActions'
import { useTranslations } from '~/src/hooks/useTranslations'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { useEoState, useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'
import { useFetchCategories } from '~/src/hooks/categories/useFetchCategories'
import { Firm } from '@eo-storefronts/eo-core'

const LocatorDeliveryTabContent = () => {
  const { t } = useTranslations()
  const brand = useEoValue(BRAND_STATE)
  const { fetch: fetchCategories } = useFetchCategories()
  const [{ firmAvailableForDelivery }, setLocatorState ] = useEoState<LocatorState>(LOCATOR_STATE)

  useEffect(() => {
    if (!firmAvailableForDelivery) {
      return
    }

    setLocatorState((state: LocatorState) => ({
      ...state,
      firmSelected: brand?.locations?.find((location: Firm) => location.id.toString() === firmAvailableForDelivery.toString()),
      isLoading: true
    }))

    fetchCategories(firmAvailableForDelivery)
      .then(() => setLocatorState((state: LocatorState) => ({
        ...state,
        isLoading: false
      })))
  }, [ firmAvailableForDelivery ])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Box
          sx={{
            button: {
              mt: 1,
              ml: 1
            }
          }}
        >
          <Typography>{t('locator.delivery.delivery_address_label')}</Typography>
          <DeliveryAddress />
        </Box>
      </Box>

      <LocatorTabActions />
    </Box>
  )
}

export default LocatorDeliveryTabContent

import { SxProps } from '@mui/material'
import StylesLocatorContainerServiceInterface
  from '~/src/components/locator/locator-container/styles/StylesLocatorContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorContainerServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorContainerServiceInterface {
  public getLocatorContainerSx(): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'minmax(500px, 30%) 1fr',
      gridTemplateAreas: '"left right"',
      gridTemplateRows: 'auto',
      [this.muiTheme.breakpoints.down('md')]: {
        gridTemplateColumns: '100%',
        gridTemplateAreas: '"left"'
      }
    }
  }
}

import { SxProps } from '@mui/material'
import StylesLocatorFirmCardActionsServiceInterface
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/StylesLocatorFirmCardActionsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmCardActionsServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorFirmCardActionsServiceInterface {
  public getButtonSx(): SxProps {
    return {
      p: 1,
      borderRadius: 'var(--btn-border-radius)',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: 'primary.main',
      transition: 'var(--ease-in-out)',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'primary.contrastText',
        color: 'primary.main',
        boxShadow: 'none'
      }
    }
  }
}

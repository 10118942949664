import StylesLocatorFirmSubTitleServiceInterface
  from '~/src/components/locator/locator-firm-sub-title/styles/StylesLocatorFirmSubTitleServiceInterface'
import StylesLocatorFirmSubTitleServiceStyleOne
  from '~/src/components/locator/locator-firm-sub-title/styles/StylesLocatorFirmSubTitleServiceStyleOne'
import StylesLocatorFirmSubTitleServiceStyleTwo
  from '~/src/components/locator/locator-firm-sub-title/styles/StylesLocatorFirmSubTitleServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorFirmSubTitleTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorFirmSubTitleServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorFirmSubTitleServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorFirmSubTitleServiceStyleOne(muiTheme)
  }
}

export default getLocatorFirmSubTitleTemplateService

import { Box, Divider, Typography } from '@mui/material'
import DeliveryMethodsDateTimeContainer from '~/src/components/delivery-methods/DeliveryMethodsDateTimeContainer'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import LocatorState, { LOCATOR_STATE } from '~/src/stores/locator'
import { DeliveryMethodNames } from '@eo-storefronts/eo-core'
import DeliveryPickupPoints from '~/src/components/delivery-methods/DeliveryPickupPoints'

const LocatorPickupPointSelector = () => {
  const { t } = useTranslations()
  const { firmSelected, isLoading } = useEoValue<LocatorState>(LOCATOR_STATE)

  if (!firmSelected) {
    return null
  }

  return (
    <Box>
      {!!firmSelected?.settings.delivery_methods[DeliveryMethodNames.PICKUP]?.pickup_points?.length && (
        <>
          <Typography>{t('locator.pickup.pickup_point_selector_label')}</Typography>
          <DeliveryPickupPoints
            isLoading={isLoading}
            variant='filled'
            size='normal'
            fullWidth
          />
          <Divider />
        </>
      )}

      <DeliveryMethodsDateTimeContainer />
    </Box>
  )
}

export default LocatorPickupPointSelector

import { SxProps } from '@mui/material'
import StylesLocatorFirmTitleServiceInterface
  from '~/src/components/locator/locator-firm-title/styles/StylesLocatorFirmTitleServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmTitleServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorFirmTitleServiceInterface {
  public getTitleSx(): SxProps {
    return {
      color: 'background.contrastText',
      [this.muiTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
      }
    }
  }
}

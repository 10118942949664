import { useEffect, useState } from 'react'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

interface ReturnsType {
  height: number,
  getHeight(): number,
}

const useGetSubHeaderHeight = (): ReturnsType => {
  const layout = useEoValue(LAYOUT_STATE)
  const { height: subHeaderHeight } = useGetDOMNodeProperties('layout_sub_header')
  const [ height, setHeight ] = useState<number>(0)

  const getHeight = (): number => {
    if (!layout.subHeader.display) {
      return 0
    }

    return Math.round((subHeaderHeight + Number.EPSILON) * 100) / 99
  }

  useEffect(() => {
    setHeight(getHeight())
  }, [
    subHeaderHeight,
    layout.subHeader.display
  ])

  return {
    height,
    getHeight
  }
}

export default useGetSubHeaderHeight

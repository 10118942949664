import StylesLocatorDeliveryMethodsServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorDeliveryMethodsServiceInterface'
import StylesLocatorDeliveryMethodsServiceStyleOne
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorDeliveryMethodsServiceStyleOne'
import StylesLocatorDeliveryMethodsServiceStyleTwo
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorDeliveryMethodsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorDeliveryMethodsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorDeliveryMethodsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorDeliveryMethodsServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorDeliveryMethodsServiceStyleOne(muiTheme)
  }
}

export default getLocatorDeliveryMethodsTemplateService

import { Box } from '@mui/material'
import LocatorMap from '~/src/components/locator/locator-map-container/locator-map'
import useGetLocatorMapContainerTemplate
  from '~/src/components/locator/locator-map-container/styles/useGetLocatorMapContainerTemplate'
import useGetHeaderHeight from '~/src/hooks/layout/useGetHeaderHeight'

const LocatorMapContainer = () => {
  const { height: headerHeight } = useGetHeaderHeight()
  const styles = useGetLocatorMapContainerTemplate()

  return (
    <Box sx={styles.getMapContainerSx(headerHeight)}>
      <LocatorMap />
    </Box>
  )
}

export default LocatorMapContainer

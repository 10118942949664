import { MarkerProps } from '@react-google-maps/api'
import { ReactNode, useState } from 'react'
import Popover from '~/src/components/mui-wrappers/styled/popover'
import CustomMapMarker from '~/src/components/map/CustomMapMarker'
import { useEoValue } from '~/src/hooks/useEoState'
import { BRAND_STATE } from '~/src/stores/brand'

interface Props extends MarkerProps {
  enablePopover?: boolean,
  children?: ReactNode,
}

const LocatorMapMarkerContainer = ({ enablePopover = true, children, ...otherProps }: Props) => {
  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const id: string | undefined = open ? 'marker-popover' : undefined
  const brand = useEoValue(BRAND_STATE)

  const handleClick = (event: google.maps.MapMouseEvent) => {
    let anchor: HTMLElement = event.domEvent.target as HTMLElement

    // Use to center the popover correctly when custom icons are not used
    // Container is different when custom icons are used
    if (!brand?.settings.theme.map_marker && anchor?.parentElement?.parentElement) {
      anchor = anchor.parentElement.parentElement
    }

    if (brand?.settings.theme.map_marker && anchor?.parentElement) {
      anchor = anchor.parentElement
    }

    setAnchorEl(anchor)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <CustomMapMarker
        mapMarker={brand?.settings.theme.map_marker}
        onClick={handleClick}
        {...otherProps}
      />

      {enablePopover && (
        <Popover
          open={open}
          id={id}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {children}
        </Popover>
      )}
    </>
  )
}

export default LocatorMapMarkerContainer

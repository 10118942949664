import { SxProps } from '@mui/material'
import StylesLocatorFirmSubTitleServiceInterface
  from '~/src/components/locator/locator-firm-sub-title/styles/StylesLocatorFirmSubTitleServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmSubTitleServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorFirmSubTitleServiceInterface {
  public getTitleSx(): SxProps {
    return {
      color: 'white',
      px: 2,
      [this.muiTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
      }
    }
  }
}

import getLocatorContainerTemplateService
  from '~/src/components/locator/locator-container/styles/GetLocatorContainerTemplateService'
import StylesLocatorContainerServiceInterface
  from '~/src/components/locator/locator-container/styles/StylesLocatorContainerServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorContainerTemplate = (): StylesLocatorContainerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorContainerTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorContainerTemplate

import { Box, Typography } from '@mui/material'
import LocatorFirmsDropdownList from '~/src/components/locator/locator-firms-dropdown-list'
import { useTranslations } from '~/src/hooks/useTranslations'

const LocatorFirmSelector = () => {
  const { t } = useTranslations()

  return (
    <Box>
      <Typography>{t('locator.pickup.firm_selector_label')}</Typography>
      <LocatorFirmsDropdownList />
    </Box>
  )
}

export default LocatorFirmSelector

import { CSSProperties } from 'react'
import StylesLocatorMapContainerServiceInterface
  from '~/src/components/locator/locator-map-container/styles/StylesLocatorMapContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorMapContainerServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorMapContainerServiceInterface {
  public getMapContainerSx(headerHeight: number): CSSProperties {
    return {
      height: `calc(var(--page-height) - ${headerHeight}px)`,
      width: '110%'
    }
  }
}

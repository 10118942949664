import StylesLocatorFirmCardActionsServiceInterface
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/StylesLocatorFirmCardActionsServiceInterface'
import StylesLocatorFirmCardActionsServiceStyleOne
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/StylesLocatorFirmCardActionsServiceStyleOne'
import StylesLocatorFirmCardActionsServiceStyleTwo
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/StylesLocatorFirmCardActionsServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorFirmCardActionsTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorFirmCardActionsServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorFirmCardActionsServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorFirmCardActionsServiceStyleOne(muiTheme)
  }
}

export default getLocatorFirmCardActionsTemplateService

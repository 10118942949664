import StylesLocatorDeliveryMethodServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorDeliveryMethodServiceInterface'
import StylesLocatorDeliveryMethodServiceStyleOne
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorDeliveryMethodServiceStyleOne'
import StylesLocatorDeliveryMethodServiceStyleTwo
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorDeliveryMethodServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorDeliveryMethodTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorDeliveryMethodServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorDeliveryMethodServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorDeliveryMethodServiceStyleOne(muiTheme)
  }
}

export default getLocatorDeliveryMethodTemplateService

import { useEffect, useRef } from 'react'
import { useEoValue } from '~/src/hooks/useEoState'
import { GOOGLE_MAPS_API_STATE } from '~/src/stores/google-maps-api'

const useGoogleMapsGeocoderService = (): google.maps.Geocoder | null => {
  const { isLoaded } = useEoValue(GOOGLE_MAPS_API_STATE)
  const geocoderService = useRef<google.maps.Geocoder | null>(null)

  useEffect(() => {
    if (!isLoaded || geocoderService.current) {
      return
    }

    geocoderService.current = new (window as any).google.maps.Geocoder()
  }, [ isLoaded ])

  return geocoderService.current
}

export default useGoogleMapsGeocoderService

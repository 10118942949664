import StylesLocatorContainerServiceInterface
  from '~/src/components/locator/locator-container/styles/StylesLocatorContainerServiceInterface'
import StylesLocatorContainerServiceStyleOne
  from '~/src/components/locator/locator-container/styles/StylesLocatorContainerServiceStyleOne'
import StylesLocatorContainerServiceStyleTwo
  from '~/src/components/locator/locator-container/styles/StylesLocatorContainerServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorContainerTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorContainerServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorContainerServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorContainerServiceStyleOne(muiTheme)
  }
}

export default getLocatorContainerTemplateService

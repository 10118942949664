import { SxProps } from '@mui/material'
import StylesLocatorDeliveryMethodServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorDeliveryMethodServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorDeliveryMethodServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorDeliveryMethodServiceInterface {
  public getTabSx(): SxProps {
    return {}
  }
}

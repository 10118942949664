import { SxProps } from '@mui/material'
import StylesLocatorContainerServiceInterface
  from '~/src/components/locator/locator-container/styles/StylesLocatorContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorContainerServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorContainerServiceInterface {
  public getLocatorContainerSx(showDeliveryMethodsFirst: boolean): SxProps {
    return {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50%)',
      gridTemplateAreas: '"left right"',
      gridTemplateRows: 'auto',
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: 'auto',
        gridTemplateRows: showDeliveryMethodsFirst ? 'auto' : 'auto auto',
        gridTemplateAreas: showDeliveryMethodsFirst ? '"left"' : '"right" "left"'
      }
    }
  }
}

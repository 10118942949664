import getTabsTemplateService
  from '~/src/components/mui-wrappers/styled/delivery-methods/tabs/styles/GetTabsTemplateService'
import StylesTabsServiceInterface
  from '~/src/components/mui-wrappers/styled/delivery-methods/tabs/styles/StylesTabsServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetTabsTemplate = (): StylesTabsServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getTabsTemplateService(pageStyle, muiTheme)
}

export default useGetTabsTemplate

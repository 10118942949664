import { DeliveryMethodEnum } from '@eo-storefronts/eo-core'
import useIsDeliveryMethodAllowed from '~/src/hooks/firm/useIsDeliveryMethodAllowed'
import { EnableableDeliveryMethod } from '~/src/types/EnableableDeliveryMethod'

interface ReturnsType {
  methods: EnableableDeliveryMethod[],
  enabledMethods: EnableableDeliveryMethod[],
  disabledMethods: EnableableDeliveryMethod[],
}

const useGetDeliveryMethod = (): ReturnsType => {
  const deliveryMethods = Object.keys(DeliveryMethodEnum).filter((key: string | number) => isNaN(Number(key)))
  const { isDeliveryMethodAllowed } = useIsDeliveryMethodAllowed()

  const _mapDeliveryMethods = (): EnableableDeliveryMethod[] => {
    return deliveryMethods.map((deliveryMethod: string) => ({
      value: deliveryMethod as DeliveryMethodEnum,
      disabled: isDeliveryMethodAllowed(deliveryMethod as DeliveryMethodEnum)
    }))
  }

  return {
    methods: _mapDeliveryMethods(),
    disabledMethods: _mapDeliveryMethods().filter((method: EnableableDeliveryMethod) => !method.disabled),
    enabledMethods: _mapDeliveryMethods().filter((method: EnableableDeliveryMethod) => method.disabled)
  }
}

export default useGetDeliveryMethod

import { DeliveryMethodEnum, Firm } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface ReturnsType {
  isDeliveryMethodAllowed(method: DeliveryMethodEnum): boolean,
}

const useIsDeliveryMethodAllowed = (): ReturnsType => {
  const firm = useEoValue(FIRM_SELECTOR)

  const isDeliveryMethodAllowed = (method: DeliveryMethodEnum): boolean => {
    if (!firm?.locations?.length) {
      return false
    }

    return firm.locations.some((firm: Firm) =>
      Object.keys(firm.settings.delivery_methods).includes(method)
    )
  }

  return { isDeliveryMethodAllowed }
}

export default useIsDeliveryMethodAllowed

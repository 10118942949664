import getTabListTemplateService
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab-list/styles/GetTabListTemplateService'
import StylesTabListServiceInterface
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab-list/styles/StylesTabListServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetTabListTemplate = (): StylesTabListServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getTabListTemplateService(pageStyle, muiTheme)
}

export default useGetTabListTemplate

import getLocatorLeftContainerTemplateService
  from '~/src/components/locator/locator-left-container/styles/GetLocatorLeftContainerTemplateService'
import StylesLocatorLeftContainerServiceInterface
  from '~/src/components/locator/locator-left-container/styles/StylesLocatorLeftContainerServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorLeftContainerTemplate = (): StylesLocatorLeftContainerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorLeftContainerTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorLeftContainerTemplate

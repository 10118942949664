import getLocatorFirmSubTitleTemplateService
  from '~/src/components/locator/locator-firm-sub-title/styles/GetLocatorFirmSubTitleTemplateService'
import StylesLocatorFirmSubTitleServiceInterface
  from '~/src/components/locator/locator-firm-sub-title/styles/StylesLocatorFirmSubTitleServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorFirmSubTitleTemplate = (): StylesLocatorFirmSubTitleServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorFirmSubTitleTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorFirmSubTitleTemplate

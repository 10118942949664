import { Firm } from '@eo-storefronts/eo-core'
import useGetLocatorFirmTitleTemplate
  from '~/src/components/locator/locator-firm-title/styles/useGetLocatorFirmTitleTemplate'
import Title from '~/src/components/mui-wrappers/Title'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const LocatorFirmTitle = () => {
  const styles = useGetLocatorFirmTitleTemplate()
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)

  if (!firm?.labels?.locator_title) {
    return null
  }

  return (
    <Title
      variant='h4'
      sx={styles.getTitleSx(firm?.settings.theme?.show_delivery_method_first)}
    >
      {firm?.labels.locator_title}
    </Title>
  )
}

export default LocatorFirmTitle

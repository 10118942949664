import { GridProps, SxProps } from '@mui/material'
import StylesLocatorLeftContainerDefaultServiceInterface
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/StylesLocatorLeftContainerDefaultServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorLeftContainerDefaultServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorLeftContainerDefaultServiceInterface {
  public getLeftContainerSx(headerHeight: number): SxProps {
    return {
      display: 'grid',
      gridTemplateAreas: '"left-container-inner-box" "left-container-filters" "left-container-location-box"',
      gridTemplateColumns: '100%',
      gridTemplateRows: 'max-content max-content auto',
      p: 3,
      pb: 0,
      backgroundColor: 'background.main',
      color: 'background.contrastText',
      height: `calc(var(--page-height) - ${headerHeight}px)`,
      zIndex: 1,
      boxShadow: '14px 0px 13px 8px rgba(0,0,0,0.1)'
    }
  }

  public getLeftContainerInnerBoxSx(): SxProps {
    return {
      gridArea: 'left-container-inner-box',
      boxSizing: 'border-box',
      borderBottomLeftRadius: '20px',
      width: '100%',
      '> img': {
        display: 'none'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        borderBottomRightRadius: '20px',
        '& > div#scroll-down-helper': {
          display: 'none'
        }
      }
    }
  }

  public getLeftContainerFilterSx(): SxProps {
    return {
      gridArea: 'left-container-filters',
      mx: 3,
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'flex',
        placeContent: 'center'
      }
    }
  }

  public getLeftContainerLocationBoxSx(): SxProps {
    return {
      gridArea: 'left-container-location-box',
      overflow: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }

  public getLeftContainerGridListSx(): SxProps {
    return {
      p: 2,
      px: 0,
      [this.muiTheme.breakpoints.only('xs')]: {
        p: 2,
        px: 0
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        p: 2,
        px: 0
      },
      [this.muiTheme.breakpoints.only('md')]: {
        p: 2,
        px: 0
      },
      [this.muiTheme.breakpoints.only('xl')]: {
        p: 2,
        px: 0
      }
    }
  }

  public getLeftContainerGridListItemsProps(): Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> {
    return {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12
    }
  }
}

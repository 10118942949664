import { DeliveryMethodEnum } from '@eo-storefronts/eo-core'
import { SyntheticEvent, useEffect } from 'react'
import LocatorDeliveryFilterMethod
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method'
import useGetLocatorDeliveryMethodsTemplate
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/useGetLocatorDeliveryMethodsTemplate'
import Tabs from '~/src/components/mui-wrappers/styled/tabs'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FilterState, LOCATOR_FILTERS_STATE, LOCATOR_INPUT_FILTER_SELECTOR, setFilters } from '~/src/stores/firms'
import useGetDeliveryMethod from '~/src/hooks/delivery-methods/useGetDeliveryMethod'
import { EnableableDeliveryMethod } from '~/src/types/EnableableDeliveryMethod'
import { FIRM_SELECTOR } from '~/src/stores/firm'

interface Props {
  displayDisabledDeliveryMethods?: boolean,
}

const LocatorDeliveryFilterMethods = ({ displayDisabledDeliveryMethods = false }: Props) => {
  const { t } = useTranslations()
  const styles = useGetLocatorDeliveryMethodsTemplate()
  const { methods, enabledMethods } = useGetDeliveryMethod()
  const firm = useEoValue(FIRM_SELECTOR)
  const filter = useEoValue(LOCATOR_INPUT_FILTER_SELECTOR('delivery_methods'))
  const setFirmFilter = useSetEoState(LOCATOR_FILTERS_STATE)

  const handleChange = (event: SyntheticEvent, value: DeliveryMethodEnum | null) => {
    setFirmFilter((state: FilterState[]) => setFilters(state, { type: 'delivery_methods', value }))
  }

  useEffect(() => {
    if (!enabledMethods.length || !firm) {
      return
    }

    setFirmFilter((state: FilterState[]) => (
      setFilters(state, { type: 'delivery_methods', value: enabledMethods[0].value })
    ))
  }, [ firm ])

  return (
    <Tabs
      id='locator__delivery__methods_tabs'
      value={filter ? filter.value : null}
      onChange={handleChange}
      displayNullTab={false}
      nullTabLabel={t('locator.filters.all')}
      sx={styles.getTabsSx()}
    >
      {methods.map((method: EnableableDeliveryMethod, index: number) => (
        <LocatorDeliveryFilterMethod
          key={index}
          value={method.value}
          displayDisabledDeliveryMethod={displayDisabledDeliveryMethods}
          disabled={!method.disabled}
        />
      ))}
    </Tabs>
  )
}

export default LocatorDeliveryFilterMethods

import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import { IconButton } from '@mui/material'
import useGetLocatorGeoPositionFilterTemplate
  from '~/src/components/locator/locator-geo-position-filter/styles/useGetLocatorGeoPositionFilterTemplate'
import { useSetEoState } from '~/src/hooks/useEoState'
import usePosition from '~/src/hooks/usePosition'
import { FilterState, LOCATOR_FILTERS_STATE, setFilters } from '~/src/stores/firms'

const LocatorGeoPositionFilter = () => {
  const styles = useGetLocatorGeoPositionFilterTemplate()
  const { myPosition } = usePosition()
  const setFirmFilter = useSetEoState(LOCATOR_FILTERS_STATE)

  const handleUseMyPosition = () => {
    setFirmFilter((state: FilterState[]) => setFilters(state, { type: 'geo', value: myPosition }))
  }

  if (!myPosition) {
    return null
  }

  return (
    <IconButton
      onClick={handleUseMyPosition}
      color='primary'
      sx={styles.getButtonSx()}
    >
      <GpsFixedIcon />
    </IconButton>
  )
}

export default LocatorGeoPositionFilter

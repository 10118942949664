import { SxProps } from '@mui/material'
import StylesLocatorFirmCardServiceInterface
  from '~/src/components/locator/locator-firm-card/styles/StylesLocatorFirmCardServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmCardServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorFirmCardServiceInterface {
  public getCardContainerSx(): SxProps {
    return {
      p: 2,
      backgroundColor: 'container.main',
      color: 'container.contrastText',
      display: 'grid',
      gridTemplateRows: '1fr',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: '"firm-card-content"',
      boxShadow: this.muiTheme.elevation,
      height: '100%',
      cursor: 'pointer'
    }
  }

  public getCardContentSx(): SxProps {
    return {
      p: 2,
      gridArea: 'firm-card-content',
      display: 'grid',
      gridTemplateAreas: '"firm-info firm-actions" "firm-timetable firm-timetable"',
      gridTemplateColumns: 'repeat(2, auto)',
      [this.muiTheme.breakpoints.only('xs')]: {
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'repeat(3, auto)',
        gridTemplateAreas: '"firm-info" "firm-timetable" "firm-actions"'
      }
    }
  }

  public getCardInfoSx(): SxProps {
    return {
      gridArea: 'firm-info'
    }
  }

  public getCardInfoTitleSx(): SxProps {
    return {
      wordBreak: 'break-word'
    }
  }

  public getCardInfoTypographySx(): SxProps {
    return {
      mt: 1,
      display: 'flex',
      alignItems: 'center'
    }
  }

  public getCardTimetableSx(): SxProps {
    return {
      gridArea: 'firm-timetable'
    }
  }

  public getCardActionsSx(): SxProps {
    return {
      gridArea: 'firm-actions',
      textAlign: 'right'
    }
  }
}

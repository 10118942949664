import { SxProps } from '@mui/material'
import StylesLocatorGeoPositionFilterServiceInterface
  from '~/src/components/locator/locator-geo-position-filter/styles/StylesLocatorGeoPositionFilterServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorGeoPositionFilterServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorGeoPositionFilterServiceInterface {
  public getButtonSx(): SxProps {
    return {}
  }
}

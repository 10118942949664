import StylesTabPanelServiceInterface
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab-panel/styles/StylesTabPanelServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabPanelServiceStyleTwo extends TemplateServiceStyleBase implements StylesTabPanelServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      width: '100%',
      fontSize: '0.875rem',
      padding: '20px',
      backgroundColor: this.muiTheme.palette.secondary.main,
      color: this.muiTheme.palette.secondary.contrastText,
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px'
    }
  }
}

import getLocatorLeftContainerDefaultTemplateService
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/GetLocatorLeftContainerDefaultTemplateService'
import StylesLocatorLeftContainerDefaultServiceInterface
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/StylesLocatorLeftContainerDefaultServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const useGetLocatorLeftContainerDefaultTemplate = (): StylesLocatorLeftContainerDefaultServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const layout = useEoValue(LAYOUT_STATE)
  const pageStyle = useGetPageStyle('locator')

  return getLocatorLeftContainerDefaultTemplateService(pageStyle, muiTheme, layout)
}

export default useGetLocatorLeftContainerDefaultTemplate

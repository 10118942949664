import getLocatorDeliveryMethodTemplateService
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/GetLocatorDeliveryMethodTemplateService'
import StylesLocatorDeliveryMethodServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/locator-delivery-filter-method/styles/StylesLocatorDeliveryMethodServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorDeliveryMethodTemplate = (): StylesLocatorDeliveryMethodServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorDeliveryMethodTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorDeliveryMethodTemplate

import getLocatorTemplateService from '~/src/pages/locator/styles/GetLocatorTemplateService'
import StylesLocatorServiceInterface from '~/src/pages/locator/styles/StylesLocatorServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetLocatorTemplate = (): StylesLocatorServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  useLayout('locator')

  return getLocatorTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorTemplate

import StylesTabsServiceInterface
  from '~/src/components/mui-wrappers/styled/delivery-methods/tabs/styles/StylesTabsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesTabsServiceStyleOne extends TemplateServiceStyleBase implements StylesTabsServiceInterface {
  public getComponentSx(): Record<string, unknown> {
    return {
      width: '100%'
    }
  }
}

import { Info } from '@eo-storefronts/eo-core'
import { Typography } from '@mui/material'
import useGetLocatorFirmSubTitleTemplate
  from '~/src/components/locator/locator-firm-sub-title/styles/useGetLocatorFirmSubTitleTemplate'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_INFO_SELECTOR } from '~/src/stores/firms'

const LocatorFirmSubTitle = () => {
  const styles = useGetLocatorFirmSubTitleTemplate()
  const firmInfo: Info | undefined = useEoValue(FIRM_INFO_SELECTOR('firm_info'))

  if (!firmInfo) {
    return null
  }

  return (
    <Typography
      component='p'
      sx={styles.getTitleSx()}
    >
      {firmInfo.content}
    </Typography>
  )
}

export default LocatorFirmSubTitle

import { Firm } from '@eo-storefronts/eo-core'
import { Box } from '@mui/material'
import useGetLocatorContainerTemplate
  from '~/src/components/locator/locator-container/styles/useGetLocatorContainerTemplate'
import LocatorLeftContainer from '~/src/components/locator/locator-left-container'
import LocatorRightContainer from '~/src/components/locator/locator-right-container'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'

const LocatorContainer = () => {
  const firm = useEoValue<Firm | null>(FIRM_SELECTOR)
  const styles = useGetLocatorContainerTemplate()

  return (
    <Box
      id='locator__container'
      sx={styles.getLocatorContainerSx(firm?.settings.theme?.show_delivery_method_first)}
    >
      <LocatorLeftContainer />
      <LocatorRightContainer />
    </Box>
  )
}

export default LocatorContainer

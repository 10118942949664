import getLocatorMapContainerTemplateService
  from '~/src/components/locator/locator-map-container/styles/GetLocatorMapContainerTemplateService'
import StylesLocatorMapContainerServiceInterface
  from '~/src/components/locator/locator-map-container/styles/StylesLocatorMapContainerServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorMapContainerTemplate = (): StylesLocatorMapContainerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorMapContainerTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorMapContainerTemplate

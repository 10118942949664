import { Firm } from '@eo-storefronts/eo-core'
import LocatorMapMarkerContainer from '~/src/components/locator/locator-map-container/locator-map-marker-container'
import LocatorMapPopoverContent from '~/src/components/locator/locator-map-container/locator-map-popover-content'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOCATOR_FILTERED_FIRMS_SELECTOR } from '~/src/stores/firms'
import { LAYOUT_STATE } from '~/src/stores/layout'
import GoogleMap from '~/src/components/map/GoogleMap'

const LocatorMap = () => {
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const firms = useEoValue<Firm[]>(LOCATOR_FILTERED_FIRMS_SELECTOR)

  return (
    <GoogleMap
      id='locator__map__google_map'
      geocodes={firms.map((firm: Firm) => firm.address.geocode)}
    >
      {firms.map((firm: Firm, index: number) => (
        <LocatorMapMarkerContainer
          key={index}
          position={{
            lat: Number(firm.address.geocode.lat),
            lng: Number(firm.address.geocode.long)
          }}
          title={firm.name}
          enablePopover={!isMobile}
        >
          <LocatorMapPopoverContent firm={firm} />
        </LocatorMapMarkerContainer>
      ))}
    </GoogleMap>
  )
}

export default LocatorMap

import getTabTemplateService
  from '~/src/components/mui-wrappers/styled/delivery-methods/tab/styles/GetTabTemplateService'
import StylesTabServiceInterface from '~/src/components/mui-wrappers/styled/tab/styles/StylesTabServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetTabTemplate = (): StylesTabServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getTabTemplateService(pageStyle, muiTheme)
}

export default useGetTabTemplate

import { SxProps } from '@mui/material'
import CurrentFirmLogo from '~/src/components/firms/CurrentFirmLogo'

interface Props {
  sx?: SxProps,
}

const LocatorCurrentFirmLogo = ({ sx }: Props) => {
  return (
    <CurrentFirmLogo
      id='firms__locator__current_firm_logo'
      sx={sx}
    />
  )
}

export default LocatorCurrentFirmLogo

import useGoogleMapsGeocoderService from '~/src/hooks/google-maps/useGoogleMapsGeocoderService'
import { debounce } from '@mui/material'

type CallbackType = (address: google.maps.GeocoderResult | null) => void

interface ReturnsType {
  fetch(placeId: string, callback: CallbackType): void,
}

const useGetPlaceDetail = (): ReturnsType => {
  const geocoderService = useGoogleMapsGeocoderService()

  const fetch = debounce((placeId: string, callback: CallbackType) => {
    if (!geocoderService) {
      return
    }

    // @ts-ignore
    geocoderService.geocode({ placeId }, (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
      if (status !== 'OK' || !results) {
        return
      }

      callback(results[0])
    })
  }, 400)

  return { fetch }
}

export default useGetPlaceDetail

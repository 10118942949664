import { SxProps } from '@mui/material'
import StylesLocatorFirmTitleServiceInterface
  from '~/src/components/locator/locator-firm-title/styles/StylesLocatorFirmTitleServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorFirmTitleServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorFirmTitleServiceInterface {
  public getTitleSx(showDeliveryMethodFirst?: boolean): SxProps {
    return {
      color: showDeliveryMethodFirst ? 'background.contrastText' : undefined,
      [this.muiTheme.breakpoints.down('md')]: {
        fontSize: '1.5rem'
      }
    }
  }
}

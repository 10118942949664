import { Firm } from '@eo-storefronts/eo-core'
import { Dispatch, SetStateAction } from 'react'
import useGetLocatorFirmCardActionsTemplate
  from '~/src/components/locator/locator-firm-card/locator-firm-card-actions/styles/useGetLocatorFirmCardActionsTemplate'
import Button from '~/src/components/mui-wrappers/buttons/Button'
import Link from '~/src/components/mui-wrappers/Link'
import useLinkFirm from '~/src/hooks/customer/useLinkFirm'
import { useTranslations } from '~/src/hooks/useTranslations'
import { LinkProps } from '@mui/material'

interface Props extends Pick<LinkProps, 'href'> {
  firm: Firm,
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
}

const LocatorFirmCardActions = ({ href, firm, isLoading, setIsLoading }: Props) => {
  const styles = useGetLocatorFirmCardActionsTemplate()
  const { doLinkFirm } = useLinkFirm()
  const { t } = useTranslations()

  const handleSelectFirm = () => {
    void doLinkFirm(firm.id)
    setIsLoading(true)
  }

  return (
    <Link
      href={href}
      resolve={false}
      removeBaseLinkStyle
    >
      <Button
        variant='contained'
        preventMultipleClick
        loading={isLoading}
        onClick={handleSelectFirm}
        sx={styles.getButtonSx()}
      >
        {t('global.choose')}
      </Button>
    </Link>
  )
}

export default LocatorFirmCardActions

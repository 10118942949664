import PlaceIcon from '@mui/icons-material/Place'
import { Box, Typography } from '@mui/material'
import Title from '~/src/components/mui-wrappers/Title'
import { Firm } from '@eo-storefronts/eo-core'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

interface Props {
  firm: Firm,
}

const LocatorFirmsDropdownListItem = ({ firm }: Props) => {
  const muiTheme = useCustomMuiTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        width: `calc(100% - ${muiTheme.spacing(5)})`
      }}
    >
      <PlaceIcon />
      <Box sx={{
        width: '100%'
      }}>
        <Title
          className='eo-ellipsis'
          variant='body1'
          sx={{
            fontWeight: 'bold'
          }}
        >
          {firm.name}
        </Title>
        <Typography
          className='eo-ellipsis'
          variant='body2'
        >
          {firm.address.address}, {firm.address.zipcode} {firm.address.locality}
        </Typography>
      </Box>
    </Box>
  )
}

export default LocatorFirmsDropdownListItem

import { ChildFirm, Firm } from '@eo-storefronts/eo-core'
import { Box, paperClasses } from '@mui/material'
import LocatorFirmCard from '~/src/components/locator/locator-firm-card'

interface Props {
  firm: ChildFirm | Firm,
}

const LocatorMapPopoverContent = ({ firm }: Props) => {
  return (
    <Box
      sx={{
        minWidth: '150px',
        maxWidth: '300px',
        [`> .${paperClasses.root}`]: {
          m: 0
        }
      }}
    >
      <LocatorFirmCard firm={firm} />
    </Box>
  )
}

export default LocatorMapPopoverContent

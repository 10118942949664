import StylesLocatorGeoPositionFilterServiceInterface
  from '~/src/components/locator/locator-geo-position-filter/styles/StylesLocatorGeoPositionFilterServiceInterface'
import StylesLocatorGeoPositionFilterServiceStyleOne
  from '~/src/components/locator/locator-geo-position-filter/styles/StylesLocatorGeoPositionFilterServiceStyleOne'
import StylesLocatorGeoPositionFilterServiceStyleTwo
  from '~/src/components/locator/locator-geo-position-filter/styles/StylesLocatorGeoPositionFilterServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorGeoPositionFilterTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorGeoPositionFilterServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorGeoPositionFilterServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorGeoPositionFilterServiceStyleOne(muiTheme)
  }
}

export default getLocatorGeoPositionFilterTemplateService

import getLocatorRightContainerTemplateService
  from '~/src/components/locator/locator-right-container/styles/GetLocatorRightContainerTemplateService'
import StylesLocatorRightContainerServiceInterface
  from '~/src/components/locator/locator-right-container/styles/StylesLocatorRightContainerServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetLocatorRightContainerTemplate = (): StylesLocatorRightContainerServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorRightContainerTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorRightContainerTemplate

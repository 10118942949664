import { Box, Slide } from '@mui/material'
import HeaderLanguageSelector from '~/src/components/layout/header/header-language-selector'
import LocatorBrandPicture from '~/src/components/locator/locator-brand-picture'
import LocatorCurrentFirmLogo from '~/src/components/locator/locator-current-firm-logo'
import LocatorDeliveryMethodsContainer from '~/src/components/locator/locator-delivery-methods-container'
import LocatorFirmSubTitle from '~/src/components/locator/locator-firm-sub-title'
import LocatorFirmTitle from '~/src/components/locator/locator-firm-title'
import useGetLocatorShowDeliveryMethodsFirstTemplate
  from '~/src/components/locator/locator-left-container/locator-show-delivery-methods-first/styles/useGetLocatorShowDeliveryMethodsFirstTemplate'
import useGetHeaderHeight from '~/src/hooks/layout/useGetHeaderHeight'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'

const LocatorShowDeliveryMethodsFirst = () => {
  const layout = useEoValue(LAYOUT_STATE)
  const { height: headerHeight } = useGetHeaderHeight()
  const styles = useGetLocatorShowDeliveryMethodsFirstTemplate()

  return (
    <Slide
      direction='right'
      in
    >
      <Box
        id='locator__delivery_methods_first_container'
        sx={styles.getContainerSx(headerHeight)}
      >
        <Box
          id='locator__delivery_methods_first_inner_container'
          sx={styles.getInnerContainerSx()}
        >
          <Box
            id='locator__delivery_methods_first_top_section'
            sx={styles.getTopSectionSx()}
          >
            {!layout.header.display || !layout.header.displayLanguagesSelector && (
              <Box
                id='locator__delivery_methods_first_top_inner_section'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: layout.header.display ? 'flex-end' : 'space-between',
                  flexShrink: 1,
                  width: '100%',
                  gap: 2,
                  '& > button': {
                    backgroundColor: 'secondary.main',
                    color: 'secondary.contrastText',
                    '&:hover': {
                      backgroundColor: 'secondary.main',
                      color: 'secondary.contrastText'
                    }
                  }
                }}>
                {!layout.header.display && <LocatorCurrentFirmLogo sx={styles.getLocatorCurrentFirmLogo()}/>}

                {!layout.header.displayLanguagesSelector && (
                  <HeaderLanguageSelector
                    variant='contained'
                    useFlag
                  />
                )}
              </Box>
            )}

            <LocatorFirmTitle/>

            <LocatorFirmSubTitle/>
          </Box>

          <Box
            id='locator__delivery_methods_first_bottom_section'
            sx={styles.getBottomSectionSx()}
          >
            <LocatorDeliveryMethodsContainer/>
          </Box>
        </Box>

        <LocatorBrandPicture/>
      </Box>
    </Slide>
  )
}

export default LocatorShowDeliveryMethodsFirst

import { SxProps } from '@mui/material'
import StylesLocatorLeftContainerServiceInterface
  from '~/src/components/locator/locator-left-container/styles/StylesLocatorLeftContainerServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorLeftContainerServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorLeftContainerServiceInterface {
  public getLeftContainerSx(): SxProps {
    return {
      gridArea: 'left',
      zIndex: 1,
      overflow: 'hidden',
      backgroundColor: 'background.main',
      color: 'background.contrastText'
    }
  }
}

import { Box } from '@mui/material'
import { useState } from 'react'
import LocatorBrandPicture from '~/src/components/locator/locator-brand-picture'
import LocatorDeliveryFiltersMethods from '~/src/components/locator/locator-filters/locator-delivery-filter-methods'
import LocatorFilters from '~/src/components/locator/locator-filters'
import LocatorFirmCard from '~/src/components/locator/locator-firm-card'
import useGetLocatorLeftContainerDefaultTemplate
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/useGetLocatorLeftContainerDefaultTemplate'
import GridList from '~/src/components/mui-wrappers/grid-list/GridList'
import GridListItem from '~/src/components/mui-wrappers/grid-list/GridListItem'
import ScrollDownIndicator from '~/src/components/ScrollDownIndicator'
import { useEoValue } from '~/src/hooks/useEoState'
import { LOCATOR_FILTERED_FIRMS_SELECTOR } from '~/src/stores/firms'
import { Firm } from '@eo-storefronts/eo-core'
import useGetHeaderHeight from '~/src/hooks/layout/useGetHeaderHeight'
import useGetMobileNavigationHeight from '~/src/hooks/layout/useGetMobileNavigationHeight'

const LocatorLeftContainerDefault = () => {
  const styles = useGetLocatorLeftContainerDefaultTemplate()
  const { height: headerHeight } = useGetHeaderHeight()
  const { height: mobileNavigationHeight } = useGetMobileNavigationHeight()
  const firms = useEoValue(LOCATOR_FILTERED_FIRMS_SELECTOR)
  const [ displayDeliveryMethods ] = useState<boolean>(true)

  return (
    <Box
      id='locator__left__container'
      component='div'
      sx={styles.getLeftContainerSx(headerHeight, mobileNavigationHeight)}
    >
      <Box
        id='locator__left__container_inner_box'
        sx={styles.getLeftContainerInnerBoxSx()}
      >
        <LocatorBrandPicture/>

        <LocatorFilters/>
      </Box>

      {displayDeliveryMethods && (
        <Box
          id='locator__left__container_filters'
          sx={styles.getLeftContainerFilterSx()}
        >
          <LocatorDeliveryFiltersMethods/>
        </Box>
      )}

      <Box
        id='locator__left__container_location_box'
        sx={styles.getLeftContainerLocationBoxSx()}
      >
        <GridList
          sx={styles.getLeftContainerGridListSx()}
          columns={12}
        >
          {firms.map((firm: Firm, index: number) => (
            <GridListItem
              {...styles.getLeftContainerGridListItemsProps()}
              key={index}
            >
              <LocatorFirmCard
                key={firm.id}
                firm={firm}
              />
            </GridListItem>
          ))}

        </GridList>

        <ScrollDownIndicator containerId='locator__left__container_location_box'/>
      </Box>
    </Box>
  )
}

export default LocatorLeftContainerDefault

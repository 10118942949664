import StylesFirmsLocatorServiceInterface
  from '~/src/components/locator/locator-filters/styles/StylesFirmsLocatorServiceInterface'
import StylesFirmsLocatorServiceStyleOne
  from '~/src/components/locator/locator-filters/styles/StylesFirmsLocatorServiceStyleOne'
import StylesFirmsLocatorServiceStyleTwo
  from '~/src/components/locator/locator-filters/styles/StylesFirmsLocatorServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getFirmsLocatorTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesFirmsLocatorServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesFirmsLocatorServiceStyleTwo(muiTheme)
    default:
      return new StylesFirmsLocatorServiceStyleOne(muiTheme)
  }
}

export default getFirmsLocatorTemplateService

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDebounce } from '~/src/hooks/useDebounce'
import useGetDOMNodeProperties from '~/src/hooks/layout/useGetDOMNodeProperties'

interface Props {
  id?: string,
  containerId?: string,
  offsetVisibility?: number,
}

const ScrollDownIndicator = ({ id, containerId, offsetVisibility = 50 }: Props) => {
  const {
    width: containerWidth,
    scrollHeight: containerScrollHeight,
    clientHeight: containerClientHeight,
    scrollTop: containerScrollTop,
    element: container
  } = useGetDOMNodeProperties(containerId)
  const [ isDisplayed, setIsDisplayed ] = useState<boolean>(true)

  const updateDisplayState = (scrollHeight: number, clientHeight: number, scrollTop: number) => {
    if (scrollHeight <= clientHeight) {
      setIsDisplayed(false)

      return
    }

    setIsDisplayed(Math.abs(scrollHeight - clientHeight - scrollTop) > offsetVisibility)
  }

  const handleContainerScroll = () => {
    const { scrollHeight, clientHeight, scrollTop } = container || document.documentElement

    updateDisplayState(scrollHeight, clientHeight, scrollTop)
  }

  const handleScrollDebounced = useDebounce(handleContainerScroll)

  const initListeners = () => {
    container?.addEventListener('scroll', handleScrollDebounced)
  }

  const clearListeners = () => {
    container?.removeEventListener('scroll', handleScrollDebounced)
  }

  useEffect(() => {
    initListeners()
    updateDisplayState(containerScrollHeight, containerClientHeight, containerScrollTop)

    return () => {
      clearListeners()
    }
  }, [ container ])

  if (!isDisplayed) {
    return null
  }

  return (
    <Box
      id={id}
      component='span'
      sx={{
        '--scroll-down-indicator-width': '2rem',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        bottom: '1rem',
        left: `calc((${containerWidth}px / 2) - (var(--scroll-down-indicator-width) / 2))`,
        width: 'var(--scroll-down-indicator-width)',
        padding: '0.4rem 0.184rem',
        height: '2.9rem',
        backgroundColor: 'container.main',
        color: 'container.contrastText',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'primary.main',
        borderRadius: '25px',
        boxSizing: 'border-box',
        boxShadow: '6px 5px 12px -4px rgb(0 0 0 / 30%)',
        transition: 'opacity 500ms',
        transitionTimingFunction: 'ease-in-out'
      }}
    >
      <ArrowDownwardIcon
        sx={{
          borderRadius: '25%',
          color: 'primary.main',
          animationName: 'scroll',
          animationDuration: '2.2s',
          animationTimingFunction: 'cubic-bezier(.15,.41,.69,.94)',
          animationIterationCount: 'infinite'
        }}
      />
    </Box>
  )
}

export default ScrollDownIndicator

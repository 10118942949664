import useGoogleMapsAutocompleteService from '~/src/hooks/google-maps/useGoogleMapsAutocompleteService'
import { Dispatch, SetStateAction, useState } from 'react'

interface ReturnsType {
  fetch(input: string): void,
  options: readonly google.maps.places.AutocompletePrediction[],
  setOptions: Dispatch<SetStateAction<readonly google.maps.places.AutocompletePrediction[]>>,
  value: google.maps.places.AutocompletePrediction | null,
  setValue: Dispatch<SetStateAction<google.maps.places.AutocompletePrediction | null>>,

}

const useGetPlacePredictions = (): ReturnsType => {
  const [ options, setOptions ] = useState<readonly google.maps.places.AutocompletePrediction[]>([])
  const [ value, setValue ] = useState<google.maps.places.AutocompletePrediction | null>(null)
  const autocompleteService = useGoogleMapsAutocompleteService()

  const fetch = (input: string) => {
    if (!autocompleteService) {
      return
    }

    // @ts-ignore
    autocompleteService.getPlacePredictions({ input }, (results?: readonly google.maps.places.AutocompletePrediction[]) => {
      let newOptions: readonly google.maps.places.AutocompletePrediction[] = []

      if (value) {
        newOptions = [ value ]
      }

      if (results) {
        newOptions = [ ...newOptions, ...results ]
      }

      setOptions(newOptions)
    })
  }

  return {
    fetch,
    options,
    setOptions,
    value,
    setValue
  }
}

export default useGetPlacePredictions

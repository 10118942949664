import { SxProps } from '@mui/material'
import StylesLocatorDeliveryMethodsServiceInterface
  from '~/src/components/locator/locator-filters/locator-delivery-filter-methods/styles/StylesLocatorDeliveryMethodsServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorDeliveryMethodsServiceStyleOne extends TemplateServiceStyleBase implements StylesLocatorDeliveryMethodsServiceInterface {
  public getTabsSx(): SxProps {
    return {}
  }
}

import getLocatorShowDeliveryMethodsFirstTemplateService
  from '~/src/components/locator/locator-left-container/locator-show-delivery-methods-first/styles/GetLocatorShowDeliveryMethodsFirstTemplateService'
import StylesLocatorShowDeliveryMethodsFirstServiceInterface
  from '~/src/components/locator/locator-left-container/locator-show-delivery-methods-first/styles/StylesLocatorShowDeliveryMethodsFirstServiceInterface'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetLocatorShowDeliveryMethodsFirstTemplate = (): StylesLocatorShowDeliveryMethodsFirstServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('locator')

  return getLocatorShowDeliveryMethodsFirstTemplateService(pageStyle, muiTheme)
}

export default useGetLocatorShowDeliveryMethodsFirstTemplate

import StylesLocatorShowDeliveryMethodsFirstServiceInterface
  from '~/src/components/locator/locator-left-container/locator-show-delivery-methods-first/styles/StylesLocatorShowDeliveryMethodsFirstServiceInterface'
import StylesLocatorShowDeliveryMethodsFirstServiceStyleOne
  from '~/src/components/locator/locator-left-container/locator-show-delivery-methods-first/styles/StylesLocatorShowDeliveryMethodsFirstServiceStyleOne'
import StylesLocatorShowDeliveryMethodsFirstServiceStyleTwo
  from '~/src/components/locator/locator-left-container/locator-show-delivery-methods-first/styles/StylesLocatorShowDeliveryMethodsFirstServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorShowDeliveryMethodsFirstTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorShowDeliveryMethodsFirstServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorShowDeliveryMethodsFirstServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorShowDeliveryMethodsFirstServiceStyleOne(muiTheme)
  }
}

export default getLocatorShowDeliveryMethodsFirstTemplateService

import StylesLocatorServiceInterface from '~/src/pages/locator/styles/StylesLocatorServiceInterface'
import StylesLocatorServiceStyleOne from '~/src/pages/locator/styles/StylesLocatorServiceStyleOne'
import StylesLocatorServiceStyleTwo from '~/src/pages/locator/styles/StylesLocatorServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorServiceStyleOne(muiTheme)
  }
}

export default getLocatorTemplateService

import { Box } from '@mui/material'
import LocatorMapContainer from '~/src/components/locator/locator-map-container'
import useGetLocatorRightContainerTemplate
  from '~/src/components/locator/locator-right-container/styles/useGetLocatorRightContainerTemplate'
import useGetHeaderHeight from '~/src/hooks/layout/useGetHeaderHeight'

const LocatorRightContainer = () => {
  const styles = useGetLocatorRightContainerTemplate()
  const { height: headerHeight } = useGetHeaderHeight()


  return (
    <Box
      id='locator__right__container'
      sx={styles.getRightContainerSx(headerHeight)}
    >
      <LocatorMapContainer />
    </Box>
  )
}

export default LocatorRightContainer

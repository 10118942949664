import { HTMLAttributes } from 'react'
import parse from 'autosuggest-highlight/parse'
import Grid from '@mui/material/Grid'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Typography from '@mui/material/Typography'
import AutocompleteRenderOptionPart
  from '~/src/components/address/address-autocomplete-using-google/AutocompleteRenderOptionPart'

interface Props extends HTMLAttributes<HTMLLIElement> {
  option: google.maps.places.AutocompletePrediction,
}

const AutocompleteRenderOption = ({ option, ...props }: Props) => {
  const matches = option.structured_formatting.main_text_matched_substrings || []
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: google.maps.places.PredictionSubstring) => [ match.offset, match.offset + match.length ])
  )

  return (
    <li {...props}>
      <Grid
        container
        alignItems='center'>
        <Grid
          item
          sx={{
            display: 'flex',
            width: 44
          }}>
          <LocationOnIcon sx={{ color: 'primary.main' }} />
        </Grid>
        <Grid
          item
          sx={{
            width: 'calc(100% - 44px)',
            wordWrap: 'break-word'
          }}>
          {parts.map((part: { highlight: boolean, text: string }, index: number) =>
            <AutocompleteRenderOptionPart
              key={index}
              part={part} />
          )}
          <Typography
            variant='subtitle2'
            sx={{
              fontStyle: 'italic',
              color: 'text.secondary'
            }}>
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  )
}

export default AutocompleteRenderOption

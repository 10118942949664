import AddressAutocompleteUsingGoogle from '~/src/components/address/address-autocomplete-using-google'
import { FilterState, LOCATOR_FILTERS_STATE, setFilters } from '~/src/stores/firms'
import { useSetEoState } from '~/src/hooks/useEoState'

const LocatorAddressFilter = () => {
  const setFirmFilter = useSetEoState(LOCATOR_FILTERS_STATE)

  const _handleOnAddressSelected = (address: google.maps.GeocoderResult | null): void => {
    if (!address) {
      return
    }

    setFirmFilter((state: FilterState[]) => setFilters(state, {
      type: 'geo', value: {
        coords: {
          latitude: address.geometry.location.lat(),
          longitude: address.geometry.location.lng()
        }
      }
    }))
  }

  return (
    <AddressAutocompleteUsingGoogle
      onAddressSelected={_handleOnAddressSelected}
    />
  )
}

export default LocatorAddressFilter

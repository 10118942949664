import { SxProps } from '@mui/material'
import StylesFirmsLocatorServiceInterface
  from '~/src/components/locator/locator-filters/styles/StylesFirmsLocatorServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesFirmsLocatorServiceStyleOne extends TemplateServiceStyleBase implements StylesFirmsLocatorServiceInterface {
  public getComponentSx(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }

  public getCurrentFirmSx(): SxProps {
    return {
      display: 'none'
    }
  }

  public getLogoTitleAndHelperTextContainerSx(): SxProps {
    return {
      flex: '1 1 100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    }
  }

  public getLogoContainerSx(): SxProps {
    return {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  }

  public getHelperTextSx(): SxProps {
    return {
      mt: 1
    }
  }

  public getFiltersContainerSx(): SxProps {
    return {
      mt: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 1,
      width: '100%',
      '& > *': {
        backgroundColor: 'transparent !important'
      }
    }
  }
}

import StylesLocatorFirmCardImageServiceInterface
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/StylesLocatorFirmCardImageServiceInterface'
import StylesLocatorFirmCardImageServiceStyleOne
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/StylesLocatorFirmCardImageServiceStyleOne'
import StylesLocatorFirmCardImageServiceStyleTwo
  from '~/src/components/locator/locator-firm-card/locator-firm-card-image/styles/StylesLocatorFirmCardImageServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getLocatorFirmCardImageTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesLocatorFirmCardImageServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesLocatorFirmCardImageServiceStyleTwo(muiTheme)
    default:
      return new StylesLocatorFirmCardImageServiceStyleOne(muiTheme)
  }
}

export default getLocatorFirmCardImageTemplateService

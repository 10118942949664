import { GridProps, SxProps, tabClasses, tabsClasses } from '@mui/material'
import StylesLocatorLeftContainerDefaultServiceInterface
  from '~/src/components/locator/locator-left-container/locator-left-container-default/styles/StylesLocatorLeftContainerDefaultServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'

export default class StylesLocatorLeftContainerDefaultServiceStyleTwo extends TemplateServiceStyleBase implements StylesLocatorLeftContainerDefaultServiceInterface {
  public getLeftContainerSx(headerHeight: number, mobileNavigationHeight: number): SxProps {
    return {
      '--firm-info-container-height': this.layout?.header.display ? '175px' : '275px',
      display: 'grid',
      gridTemplateAreas: '"left-container-inner-box" "left-container-filters" "left-container-location-box"',
      gridTemplateColumns: '100%',
      gridTemplateRows: 'var(--firm-info-container-height) max-content auto',
      backgroundColor: 'background.main',
      color: 'background.contrastText',
      borderBottomRightRadius: '20px',
      zIndex: 1,
      boxShadow: '14px 0px 13px 8px rgba(0,0,0,0.1)',
      height: `calc(var(--page-height) - ${headerHeight}px)`,
      [this.muiTheme.breakpoints.down('md')]: {
        borderBottomRightRadius: 0,
        gridTemplateRows: 'calc(var(--firm-info-container-height) + var(--eo-safe-area-top)) max-content auto',
        height: `calc(var(--page-height) - ${headerHeight}px - ${mobileNavigationHeight}px)`
      },
      [this.muiTheme.breakpoints.up('2k')]: {
        gridTemplateRows: '250px max-content auto'
      }
    }
  }

  public getLeftContainerInnerBoxSx(): SxProps {
    return {
      gridArea: 'left-container-inner-box',
      position: 'relative',
      boxSizing: 'border-box',
      overflow: 'hidden',
      borderBottomLeftRadius: '20px',
      height: 'var(--firm-info-container-height)',
      width: '100%',
      '> img': {
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        transform: 'scale(1.02)',
        top: '-4px',
        width: '100%'
      },
      [this.muiTheme.breakpoints.down('md')]: {
        height: 'calc(var(--firm-info-container-height) + var(--eo-safe-area-top))',
        borderBottomRightRadius: '20px'
      },
      [this.muiTheme.breakpoints.up('2k')]: {
        height: '250px'
      }
    }
  }

  public getLeftContainerFilterSx(): SxProps {
    return {
      gridArea: 'left-container-filters',
      p: 2,
      [`.${tabsClasses.root}`]: {
        [`.${tabsClasses.flexContainer}`]: {
          gap: 1,
          [`.${tabClasses.root}`]: {
            margin: 0
          }
        }
      },
      [this.muiTheme.breakpoints.down('md')]: {
        display: 'flex',
        placeContent: 'center'
      }
    }
  }

  public getLeftContainerLocationBoxSx(): SxProps {
    return {
      gridArea: 'left-container-location-box',
      overflow: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }

  public getLeftContainerGridListSx(): SxProps {
    return {
      p: 2,
      paddingTop: 0,
      [this.muiTheme.breakpoints.only('xs')]: {
        p: 2,
        paddingTop: 0
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        p: 2,
        paddingTop: 0
      },
      [this.muiTheme.breakpoints.only('md')]: {
        p: 2,
        paddingTop: 0
      },
      [this.muiTheme.breakpoints.only('xl')]: {
        p: 2,
        paddingTop: 0
      }
    }
  }

  public getLeftContainerGridListItemsProps(): Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'> {
    return {
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
      xl: 6
    }
  }
}
